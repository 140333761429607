<template>
  <a-form
    ref="resetPasswordForm"
    :model="resetPasswordForm"
    :rules="resetPasswordRules"
    class="columns is-multiline"
  >
    <a-form-item v-if="showEmailField" class="column is-12" :label="$t('Email')" name="email">
      <a-input
        :value="email"
        :size="formSize"
        autocomplete="email"
        type="email"
        :disabled="true"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Password')" name="password">
      <a-input-password
        v-model:value.trim="resetPasswordForm.password"
        :size="formSize"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Retype Password')" name="retypePassword">
      <a-input-password
        v-model:value.trim="resetPasswordForm.retypePassword"
        :size="formSize"
      />
    </a-form-item>
    <div class="column is-12 m-t-4x">
      <a-button
        :loading="isSubmitting"
        :size="formSize"
        class="m-r-3x"
        style="min-width: 155px"
        type="primary"
        @click="handleSubmit"
      >
        Save
      </a-button>
    </div>
    <div v-if="error" class="column is-12 m-t-1x">
      <a-alert :message="error" type="error" />
    </div>
  </a-form>
</template>

<i18n>
{
"en":{
"Email": "Email",
"Password":"Password",
"Retype Password":"Retype Password",
"password-min":"Password must be 6 characters or more.",
"password-required":"Password is required.",
"retype-password-required":"Retype Password is required.",
"password-not-match": "Password does not match.",
"Save": "Save"
},
"ja":{
"Email": "メールアドレス",
"Password":"パスワード",
"Retype Password":"パスワードを再入力",
"password-min":"パスワードは6桁以上である必要があります",
"password-required":"パスワードは必須です",
"retype-password-required":"パスワードの再入力は必須です",
"password-not-match": "パスワードがマッチしません",
"Save": "保存"
}
}
</i18n>

<script>
export default {
  name: 'ResetPasswordForm',
  props: {
    isSubmitting: {
      type: Boolean,
    },
    showEmailField: {
      type: Boolean,
    },
    email: {
      type: String,
      default: undefined,
    },
    error: {
      type: String,
      default: undefined,
    },
  },
  emits: ['submit'],
  data() {
    return {
      resetPasswordForm: {
        password: '',
        retypePassword: '',
      },
      resetPasswordRules: {
        password: [
          {
            required: true,
            message: this.$t('password-required'),
            trigger: ['change', 'blur'],
          },
          {
            min: 6,
            message: this.$t('password-min'),
            trigger: ['change', 'blur'],
          },
        ],
        retypePassword: [
          {
            type: 'string',
            required: true,
            message: this.$t('retype-password-required'),
            trigger: ['change', 'blur'],
          },
          {
            type: 'string',
            min: 6,
            message: this.$t('password-min'),
            trigger: ['change', 'blur'],
          },
          {
            type: 'string',
            validator: async (rule, value) => {
              if (value !== this.resetPasswordForm.password) {
                return Promise.reject(this.$t('password-not-match'));
              }
              return Promise.resolve();
            },
            trigger: ['change', 'blur'],
          },
        ],
      },
      formSize: 'large',
    };
  },
  methods: {
    handleSubmit() {
      this.$refs.resetPasswordForm
        .validate()
        .then(() => {
          this.$emit('submit', { ...this.resetPasswordForm });
        })
        .catch(() => {});
    },
  },
};
</script>
